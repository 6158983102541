<template>
  <div
    class="pwa-install-modal"
    :class="{ mobile: isMobile }"
    data-t="pwa-install-modal"
    data-testid="pwa-install-modal"
  >
    <h2 class="title" data-t="modal-title">{{ t('pwaModal.title') }}</h2>
    <div class="list">
      <p class="list-item">
        <img
          class="list-image"
          src="./images/speedometer.png"
          alt="Speedometer"
          data-t="list-image-speedometer"
        />
        <span class="list-title" data-t="list-title-speedometer">
          {{ t('pwaModal.fastDownload') }}
        </span>
      </p>
      <p class="list-item">
        <img
          class="list-image"
          src="./images/notification.png"
          alt="Notification"
          data-t="list-image-notification"
        />
        <span class="list-title" data-t="list-title-notification">
          {{ t('pwaModal.firstToKnow') }}
        </span>
      </p>
      <p class="list-item">
        <img
          class="list-image"
          src="./images/toggle.png"
          alt="Toggle"
          data-t="list-image-toggle"
        />
        <span class="list-title" data-t="list-title-toggle">
          {{ t('pwaModal.alwaysAtHand') }}
        </span>
      </p>
    </div>

    <div class="instructions" :class="browserName">
      <h3 class="instructions-title" data-t="instructions-title">
        {{ browserInstructions.title }}
      </h3>
      <img class="browser-image" :src="browserImage" data-t="browser-image" />
      <div class="steps-wrapper">
        <p class="instructions-step">
          <StIcon
            :name="browserInstructions.icons[0]"
            size="24"
            data-t="instruction-step1-icon"
          />
          <span class="instruction-title" data-t="instruction-step1-title">
            {{ browserInstructions.step1 }}
          </span>
        </p>
        <p class="instructions-step">
          <StIcon
            :name="browserInstructions.icons[1]"
            size="24"
            data-t="instruction-step2-icon"
          />
          <span class="instruction-title" data-t="instruction-step2-title">
            {{ browserInstructions.step2 }}
          </span>
        </p>
      </div>
    </div>

    <div class="button-wrapper" data-t="button-wrapper">
      <StButton
        class="button"
        :label="t('pwaModal.moreOptions')"
        :size="isMobile ? 'l' : 'xl'"
        type="gray"
        data-t="modal-button"
        @click="$router.push('/pwa')"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import safariMobile from './images/safari-mobile.png'
import safari from './images/safari.png'
import samsung from './images/samsung.png'
import google from './images/google.png'

const { t } = useI18n()
const { isMobile } = usePlatform()

type BrowserType =
  | 'safari-mobile'
  | 'google-chrome'
  | 'samsung-internet'
  | 'safari'

const props = defineProps<{
  browser: BrowserType
}>()

const browserImageMap: Record<BrowserType, string> = {
  'safari-mobile': safariMobile,
  'google-chrome': google,
  'samsung-internet': samsung,
  safari,
}

const browserInstructionsMap: Record<
  BrowserType,
  { title: string; step1: string; step2: string; icons: string[] }
> = {
  'safari-mobile': {
    title: t('pwaModal.safariBrowser'),
    step1: t('pwaModal.instructions.safari.step1'),
    step2: t('pwaModal.instructions.safari.step2'),
    icons: ['share', 'add'],
  },
  'google-chrome': {
    title: t('pwaModal.googleChromeBrowser'),
    step1: t('pwaModal.instructions.googleChrome.step1'),
    step2: t('pwaModal.instructions.googleChrome.step2'),
    icons: ['share', 'plus-square'],
  },
  'samsung-internet': {
    title: t('pwaModal.samsungInternetBrowser'),
    step1: t('pwaModal.instructions.samsungInternet.step1'),
    step2: t('pwaModal.instructions.samsungInternet.step2'),
    icons: ['dots-vertical', 'mobile-phone-add'],
  },
  safari: {
    title: t('pwaModal.safariBrowser'),
    step1: t('pwaModal.instructions.safari.step1'),
    step2: t('pwaModal.instructions.safari.step2'),
    icons: ['share', 'add'],
  },
}

const browserName = computed<BrowserType>(() => {
  const browser = props.browser as BrowserType
  if (isMobile.value) {
    return browser === 'safari' ? 'safari-mobile' : browser
  }

  return browser ?? 'safari'
})

const browserImage = computed(() => browserImageMap[browserName.value])
const browserInstructions = computed(
  () => browserInstructionsMap[browserName.value],
)
</script>

<style scoped>
h2,
h3,
p {
  margin: 0;
}

.pwa-install-modal {
  position: relative;

  width: 596px;
  padding: var(--spacing-300);

  background: var(--background-base, #111118);
  border-radius: var(--border-radius-150, 12px);
  box-shadow: 0 0 80px -8px #1d1d2a;
}

.title {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-2xl-semibold);
}

.list {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-250);
}

.list-item {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
  margin: 0;
}

.list-image {
  width: 32px;
  height: 32px;
}

.list-title {
  font: var(--desktop-text-md-semibold);
  color: var(--text-secondary);
}

.instructions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;
  align-self: stretch;
  justify-content: center;

  padding: var(--spacing-250) var(--spacing-300) var(--spacing-300)
    var(--spacing-300);

  border: var(--border-width-medium) solid var(--border-primary);
  border-radius: var(--border-radius-150);
}

.instructions-title {
  font: var(--desktop-text-xl-semibold);
}

.browser-image {
  width: 296px;
}

.instructions-step {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  font: var(--desktop-text-sm-medium);

  i {
    min-width: 24px;
  }
}

.instruction-title {
  color: var(--text-secondary);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: var(--spacing-075);
}

.button {
  width: 100%;
  margin-top: var(--spacing-200);
}

.steps-wrapper {
  z-index: 0;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-125);

  width: 100%;
}

.pwa-install-modal.mobile {
  width: 100%;
  padding: var(--spacing-250) 0 0 0;

  .title {
    margin: 0;
    padding: var(--spacing-150) var(--spacing-200);
    font: var(--mobile-title-2-semibold);
    border-bottom: var(--border-width-medium) solid var(--border-primary);
  }

  .list {
    margin-bottom: 0;
    padding: var(--spacing-150) var(--spacing-200);
  }

  .instructions {
    margin: 0 var(--spacing-200);
    padding: var(--spacing-200);
  }

  .button {
    margin: 0;
  }

  .button-wrapper {
    padding: var(--spacing-200);
  }

  .safari-mobile .st-icon {
    color: rgb(10 132 255 / 100%);
  }

  .list-title {
    max-width: 80px;
  }

  .instructions-title {
    width: 100%;
    font: var(--mobile-headline-semibold);
    text-align: center;
  }

  .browser-image {
    width: 240px;
    margin: 0 auto;
  }

  .instruction-title {
    font: var(--mobile-text-content-regular);
  }

  .instructions-step {
    align-items: flex-start;
  }
}
</style>
